import React from 'react'
import {
  List,
  Datagrid,
  Edit,
  SimpleForm,
  TextField,
  EditButton,
  Show,
  NumberInput,
  Filter,
  TextInput,
  TabbedShowLayout,
  Tab,
  required,
  Create,
  DateField,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceManyField,
  Pagination,
  AutocompleteInput,
  NumberField,
  DeleteButton,
  SimpleShowLayout,
  DateTimeInput,
  DateInput,
  FormDataConsumer,
  BooleanField,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import Icon from '@material-ui/icons/Kitchen'
import HiddenUserInput from './HiddenUserInput'
import isAdmin from '../utils/isAdmin'
import emailFilterToQuery from '../utils/emailFilterToQuery'
export const MealIcon = Icon

const MealFilter = props => (
  <Filter {...props}>
    <TextInput source="name_contains" label="Name" alwaysOn />
    <DateInput source="date_gte" label="Date After" alwaysOn />
    <DateInput source="date_lte" label="Date Before" alwaysOn />
    <NumberInput source="hour_gte" label="After Day Hour" alwaysOn />
    <NumberInput source="hour_lte" label="Before Day Hour" alwaysOn />
  </Filter>
)

const mealRowStyle = (record) => ({
  backgroundColor: record.hasExceededCalories ? '#ec8787' : '#87ec87',
})
export const MealList = props => {
  return (
    <List {...props} filters={<MealFilter />}>
      <Datagrid rowClick="show" rowStyle={mealRowStyle}>
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="calories" />
        <DateField source="date" showTime />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const MealTitle = ({ record }) => {
  return <span>Meal {record ? `"${record.name}"` : ''}</span>
}
const getHour = date => {
  return new Date(date).getHours()
}
export const MealEdit = props => (
  <Edit title={<MealTitle />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={required()} />
      <TextInput source="description" validate={required()} />
      <NumberInput source="calories" validate={required()} />
      <DateTimeInput source="date" validate={required()} />
      <FormDataConsumer>
        {({
            formData,
            ...rest,
          }) =>
          <NumberInput
            source="hour"
            defaultValue={getHour(formData.date)}
            style={{ display: 'none' }}
            {...rest}
          />
        }
      </FormDataConsumer>
      <HiddenUserInput {...props} />
    </SimpleForm>
  </Edit>
)

export const MealShow = props => (
  <Show title={<MealTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="calories" />
      <DateField source="date" showTime />
      <BooleanField source="hasExceededCalories" />
    </SimpleShowLayout>
  </Show>
)

export const MealCreate = props => {
  return (
    <Create title="Create Meal" {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" validate={required()} />
        <TextInput source="description" validate={required()} />
        <NumberInput source="calories" validate={required()} />
        <DateTimeInput source="date" validate={required()} parse={val => new Date(val || '').toISOString()} />
        <FormDataConsumer>
          {({
              formData,
              ...rest,
            }) =>
            <NumberInput
              source="hour"
              defaultValue={getHour(formData.date) || 0}
              style={{ display: 'none' }}
              {...rest}
            />
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isAdmin() ? (
              <ReferenceInput
                label="User"
                source="user.id"
                reference="User"
                {...rest}
                filterToQuery={emailFilterToQuery}
                validate={required()}
              >
                <AutocompleteInput optionText="email">
                  <SelectInput />
                </AutocompleteInput>
              </ReferenceInput>
            ) : <HiddenUserInput {...props} />
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}
