import React from 'react'
import { map } from 'lodash'
import {
  List,
  Datagrid,
  Edit,
  SimpleForm,
  TextField,
  EditButton,
  Show,
  NumberInput,
  Filter,
  TextInput,
  TabbedShowLayout,
  Tab,
  required,
  Create,
  DateField,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceManyField,
  Pagination,
  AutocompleteInput,
  NumberField,
  DeleteButton,
  SimpleShowLayout,
  SelectField,
  SelectInput,
} from 'react-admin'
import Icon from '@material-ui/icons/SupervisorAccount'
import HiddenUserInput from './HiddenUserInput'
import optionText from '../utils/optionText'
export const UserIcon = Icon

const UserFilter = props => (
  <Filter {...props}>
    <TextInput source="email_contains" label="Email" alwaysOn />
  </Filter>
)

export const UserList = props => {
  return (
    <List {...props} filters={<UserFilter />}>
      <Datagrid rowClick="show">
        <TextField source="email" />
        <TextField source="calories" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const UserTitle = ({ record }) => {
  return <span>User {record ? `"${record.email}"` : ''}</span>
}

export const UserEdit = props => (
  <Edit title={<UserTitle />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="email" validate={required()} type="email" />
      <NumberInput source="calories" validate={required()} />
      <HiddenUserInput {...props} />
    </SimpleForm>
  </Edit>
)

export const UserShow = props => (
  <Show title={<UserTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="email" />
      <TextField source="calories" />
      <SelectField
        source="role"
        label="Role"
        choices={RoleType}
        optionText={optionText}
      />
    </SimpleShowLayout>
  </Show>
)

const RoleType = map(
  ['Regular', 'UserManager', 'Admin'],
  id => ({ id }),
)
export const UserCreate = props => {
  return (
    <Create title="Create User" {...props}>
      <SimpleForm redirect="list">
        <TextInput source="email" validate={required()} />
        <TextInput source="netlifyId" defaultValue="dummy" style={{ display: 'none' }}/>
        <NumberInput source="calories" />
        <SelectInput
          defaultValue="Regular"
          source="role"
          label="Role"
          choices={RoleType}
          optionText={optionText}
        />
      </SimpleForm>
    </Create>
  )
}
