import { GET_ONE, GET_LIST, GET_MANY, GET_MANY_REFERENCE } from 'react-admin'
import { get, reduce, set } from 'lodash'
import mealFragment from '../fragments/mealFragment.graphql'

const buildOverrideQuery = (resourceType, fragment) =>
  reduce(
    [GET_ONE, GET_LIST, GET_MANY, GET_MANY_REFERENCE],
    (acc, fetchType) => set(acc, fetchType, fragment),
    {},
  )
const overridenQueries = reduce(
  [
    { resourceType: 'Meal', fragment: mealFragment },
  ],
  (acc, { resourceType, fragment }) =>
    set(acc, resourceType, buildOverrideQuery(resourceType, fragment)),
  {},
)
export default buildQuery => introspectionResults => (
  fetchType,
  resourceName,
  params,
) => {
  const fragment = get(overridenQueries, `${resourceName}.${fetchType}`)

  return buildQuery(introspectionResults)(
    fetchType,
    resourceName,
    params,
    fragment,
  )
}
