import React from 'react'
import { DisabledInput } from 'react-admin'

export default props => {
  return (
    <DisabledInput
      defaultValue={localStorage.getItem('id')}
      source={props.source || 'user.id'}
      style={{ display: 'none' }}
    />
  )
}
